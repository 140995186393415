var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.voltar()
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-input", {
            attrs: {
              name: "solicitacaoInternacao",
              label: "Solicitação de internação",
              modelErrors: _vm.errors,
              cols: 3,
              colsXL: 2,
              disabled: ""
            },
            model: {
              value: _vm.model.solicitacaoInternacao,
              callback: function($$v) {
                _vm.$set(_vm.model, "solicitacaoInternacao", $$v)
              },
              expression: "model.solicitacaoInternacao"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "hospital",
              label: "Hospital",
              modelErrors: _vm.errors,
              cols: 4,
              colsXL: 3,
              disabled: ""
            },
            model: {
              value: _vm.model.hospital,
              callback: function($$v) {
                _vm.$set(_vm.model, "hospital", $$v)
              },
              expression: "model.hospital"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "rede",
              label: "Rede",
              modelErrors: _vm.errors,
              cols: 2,
              colsXl: 1,
              disabled: ""
            },
            model: {
              value: _vm.model.rede,
              callback: function($$v) {
                _vm.$set(_vm.model, "rede", $$v)
              },
              expression: "model.rede"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "tipoGuia",
              label: "Tipo de guia",
              modelErrors: _vm.errors,
              cols: 3,
              colsXL: 2,
              disabled: ""
            },
            model: {
              value: _vm.model.tipoGuia,
              callback: function($$v) {
                _vm.$set(_vm.model, "tipoGuia", $$v)
              },
              expression: "model.tipoGuia"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "periodoFaturamento",
              label: "Período de Faturamento",
              modelErrors: _vm.errors,
              cols: 4,
              colsXL: 3,
              disabled: ""
            },
            model: {
              value: _vm.periodoFaturamento,
              callback: function($$v) {
                _vm.periodoFaturamento = $$v
              },
              expression: "periodoFaturamento"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "convenio",
              label: "Convênio",
              modelErrors: _vm.errors,
              cols: 2,
              disabled: ""
            },
            model: {
              value: _vm.model.convenio,
              callback: function($$v) {
                _vm.$set(_vm.model, "convenio", $$v)
              },
              expression: "model.convenio"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "auditadoPor",
              label: "Auditado por",
              modelErrors: _vm.errors,
              cols: 3,
              disabled: ""
            },
            model: {
              value: _vm.model.auditadoPor,
              callback: function($$v) {
                _vm.$set(_vm.model, "auditadoPor", $$v)
              },
              expression: "model.auditadoPor"
            }
          }),
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "fieldset",
              {
                directives: [
                  {
                    name: "authorize",
                    rawName: "v-authorize",
                    value: ["lotesScLotesAuditar", "lotesScLotesReabrir"],
                    expression: "['lotesScLotesAuditar', 'lotesScLotesReabrir']"
                  }
                ],
                staticClass: "form-group mb-3 form-group-input"
              },
              [
                _c(
                  "legend",
                  {
                    staticClass: "bv-no-focus-ring col-form-label pt-0",
                    staticStyle: { color: "white" },
                    attrs: { tabindex: "-1" }
                  },
                  [_vm._v(".")]
                ),
                _c(
                  "div",
                  [
                    !_vm.disabled && !_vm.guiaEncerrada
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: "lotesScLotesAuditar",
                                expression: "'lotesScLotesAuditar'"
                              }
                            ],
                            attrs: { pill: "", variant: "primary" },
                            on: { click: _vm.encerrarAuditoria }
                          },
                          [
                            _c("i", { staticClass: "uil uil-save" }),
                            _vm._v(" Encerrar auditoria ")
                          ]
                        )
                      : _vm._e(),
                    _vm.guiaEncerrada
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: "lotesScLotesReabrir",
                                expression: "'lotesScLotesReabrir'"
                              }
                            ],
                            attrs: { pill: "", variant: "info" },
                            on: { click: _vm.reabrirAuditoria }
                          },
                          [
                            _c("i", {
                              staticClass: "uil uil-sync-exclamation"
                            }),
                            _vm._v(" Reabrir auditoria ")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]),
          !_vm.disabled &&
          !_vm.guiaEncerrada &&
          _vm.userAttrId == _vm.ATRIBUICAO.ADMINISTRATIVO
            ? _c("f-input", {
                attrs: {
                  name: "representandoUsuario",
                  label: "Representando",
                  modelErrors: _vm.errors,
                  cols: 5,
                  disabled: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "light" },
                              on: { click: _vm.trocarRepresentante }
                            },
                            [_c("i", { staticClass: "uil uil-sync" })]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2701163702
                ),
                model: {
                  value: _vm.model.representacao.label,
                  callback: function($$v) {
                    _vm.$set(_vm.model.representacao, "label", $$v)
                  },
                  expression: "model.representacao.label"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 filtro-inline" },
          [
            _c("b-form-group", {
              attrs: { label: "Filtros" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var ariaDescribedby = ref.ariaDescribedby
                    return [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "filtroTabela",
                          options: _vm.filtroTabela,
                          "aria-describedby": ariaDescribedby,
                          name: "filtroTabela"
                        },
                        model: {
                          value: _vm.filtro.tabela,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "tabela", $$v)
                          },
                          expression: "filtro.tabela"
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 filtro-inline" },
          [
            _c("b-form-group", {
              attrs: { label: "Glosas" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var ariaDescribedby = ref.ariaDescribedby
                    return [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "filtroGlosas",
                          options: _vm.filtroGlosas,
                          "aria-describedby": ariaDescribedby,
                          name: "filtroGlosas"
                        },
                        model: {
                          value: _vm.filtro.glosas,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "glosas", $$v)
                          },
                          expression: "filtro.glosas"
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-9 filtro-inline" },
          [
            _c("b-form-group", {
              attrs: { label: "Status" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var ariaDescribedby = ref.ariaDescribedby
                    return [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "filtroStatus",
                          options: _vm.filtroStatus,
                          "aria-describedby": ariaDescribedby,
                          name: "filtroStatus"
                        },
                        model: {
                          value: _vm.filtro.status,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro, "status", $$v)
                          },
                          expression: "filtro.status"
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          responsive: "",
          items: _vm.model.itensGuia,
          fields: _vm.fields,
          filter: _vm.filtro,
          "filter-function": _vm.filtroExec,
          "primary-key": "id"
        },
        scopedSlots: _vm._u([
          {
            key: "head(selection)",
            fn: function() {
              return [
                _vm.filtro.selecao.length > 0
                  ? _c("b-form-checkbox", {
                      attrs: { title: "Desmarcar similares" },
                      model: {
                        value: _vm.variosSelecionados,
                        callback: function($$v) {
                          _vm.variosSelecionados = $$v
                        },
                        expression: "variosSelecionados"
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "cell(selection)",
            fn: function(data) {
              return [
                _vm.existemSimilares(data.item.id)
                  ? _c("b-form-checkbox", {
                      attrs: {
                        value: data.item.id,
                        title: "Selecionar similares"
                      },
                      model: {
                        value: _vm.filtro.selecao,
                        callback: function($$v) {
                          _vm.$set(_vm.filtro, "selecao", $$v)
                        },
                        expression: "filtro.selecao"
                      }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(data) {
              return [
                _c(
                  "b-button-group",
                  { attrs: { size: "sm" } },
                  [
                    _vm.disabled ||
                    _vm.guiaEncerrada ||
                    (_vm.userRepAttrId == _vm.ATRIBUICAO.ADMINISTRATIVO &&
                      !(
                        _vm.model.representacao &&
                        _vm.model.representacao.id > 0
                      )) ||
                    (data.item.ultimaAtribuicaoExecutouGlosa &&
                      data.item.ultimaAtribuicaoExecutouGlosa <
                        _vm.userRepAttrId)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: "lotesScLotesAuditar",
                                expression: "'lotesScLotesAuditar'"
                              }
                            ],
                            attrs: {
                              variant: "outline-primary",
                              title:
                                data.item.ultimaAtribuicaoExecutouGlosa ==
                                _vm.ATRIBUICAO.MEDICO
                                  ? "editado por médico"
                                  : "ver"
                            },
                            on: {
                              click: function($event) {
                                _vm.$router.push({
                                  name:
                                    "faturamento.lotes.formauditoria.visualizar",
                                  params: {
                                    arquivoId: _vm.$route.params.arquivoId,
                                    guiaIndex: _vm.$route.params.guiaIndex,
                                    numeroLote: _vm.$route.params.numeroLote,
                                    itemGuiaIds: JSON.stringify(
                                      _vm.filtro.selecao.length > 0
                                        ? _vm.filtro.selecao
                                        : [data.item.id]
                                    )
                                  }
                                })
                              }
                            }
                          },
                          [_c("i", { staticClass: "uil uil-eye icon-size" })]
                        )
                      : _vm._e(),
                    !_vm.disabled &&
                    !_vm.guiaEncerrada &&
                    _vm.userAttrId &&
                    (_vm.userRepAttrId != _vm.ATRIBUICAO.ADMINISTRATIVO ||
                      (_vm.model.representacao &&
                        _vm.model.representacao.id > 0)) &&
                    (!data.item.ultimaAtribuicaoExecutouGlosa ||
                      data.item.ultimaAtribuicaoExecutouGlosa >=
                        _vm.userRepAttrId)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: "lotesScLotesAuditar",
                                expression: "'lotesScLotesAuditar'"
                              }
                            ],
                            attrs: {
                              variant: "outline-primary",
                              title:
                                "Auditar" +
                                (_vm.filtro.selecao.length > 0
                                  ? " selecionados"
                                  : ""),
                              small: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.$router.push({
                                  name:
                                    "faturamento.lotes.formauditoria.auditar",
                                  params: {
                                    arquivoId: _vm.$route.params.arquivoId,
                                    guiaIndex: _vm.$route.params.guiaIndex,
                                    numeroLote: _vm.$route.params.numeroLote,
                                    itemGuiaIds: JSON.stringify(
                                      _vm.filtro.selecao.length > 0
                                        ? _vm.filtro.selecao
                                        : [data.item.id]
                                    )
                                  }
                                })
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "uil uil-file-edit-alt icon-size"
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          ref: "modal-representacao",
          attrs: {
            title: "Representação",
            hideHeaderClose: true,
            centered: true,
            "ok-only": ""
          },
          on: {
            hidden: _vm.ignorarRepresentante,
            ok: _vm.utilizarRepresentante
          }
        },
        [
          _c(
            "div",
            { staticClass: "filtro-inline" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Selecione o tipo de usuário:" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "representacaoTipo",
                      options: _vm.modalRepresentacao.optsAtribuicao,
                      name: "representacaoTipo"
                    },
                    model: {
                      value: _vm.modalRepresentacao.representanteTipoId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.modalRepresentacao,
                          "representanteTipoId",
                          $$v
                        )
                      },
                      expression: "modalRepresentacao.representanteTipoId"
                    }
                  })
                ],
                1
              ),
              _c("f-selectsearch", {
                ref: "modalUsuarioId",
                attrs: {
                  label: "Selecione o usuário:",
                  name: "modalUsuarioId",
                  disabled: !_vm.modalRepresentacao.representanteTipoId,
                  cols: 12,
                  searchFunction: _vm.pesquisarRepresentante,
                  labelField: "nome"
                },
                on: { select: _vm.addRepresentante },
                model: {
                  value: _vm.modalRepresentacao.representanteId,
                  callback: function($$v) {
                    _vm.$set(_vm.modalRepresentacao, "representanteId", $$v)
                  },
                  expression: "modalRepresentacao.representanteId"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }