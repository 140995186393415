import { render, staticRenderFns } from "./DetalheGuia.vue?vue&type=template&id=b0bfec08&"
import script from "./DetalheGuia.vue?vue&type=script&lang=js&"
export * from "./DetalheGuia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Source\\openproject\\go-care\\Frontend\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b0bfec08')) {
      api.createRecord('b0bfec08', component.options)
    } else {
      api.reload('b0bfec08', component.options)
    }
    module.hot.accept("./DetalheGuia.vue?vue&type=template&id=b0bfec08&", function () {
      api.rerender('b0bfec08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/lotes/DetalheGuia.vue"
export default component.exports