<template>
  <card-form>
    
    <div class="row mt-4">
      <f-input 
        name="solicitacaoInternacao"
        v-model="model.solicitacaoInternacao" 
        label="Solicitação de internação"
        :modelErrors="errors"
        :cols="3"
        :colsXL="2"
        disabled
      />

      <f-input 
        name="hospital"
        v-model="model.hospital" 
        label="Hospital"
        :modelErrors="errors"
        :cols="4"
        :colsXL="3"
        disabled
      />

      <f-input 
        name="rede"
        v-model="model.rede" 
        label="Rede"
        :modelErrors="errors"
        :cols="2"
        :colsXl="1"
        disabled
      />

      <f-input 
        name="tipoGuia"
        v-model="model.tipoGuia" 
        label="Tipo de guia"
        :modelErrors="errors"
        :cols="3"
        :colsXL="2"
        disabled
      />

      <f-input 
        name="periodoFaturamento" 
        v-model="periodoFaturamento" 
        label="Período de Faturamento"
        :modelErrors="errors"
        :cols="4"
        :colsXL="3"
        disabled
      />

      <f-input 
        name="convenio" 
        v-model="model.convenio" 
        label="Convênio"
        :modelErrors="errors"
        :cols="2"
        disabled
      />

      <f-input
        name="auditadoPor" 
        v-model="model.auditadoPor" 
        label="Auditado por"
        :modelErrors="errors"
        :cols="3"
        disabled
      />

      <div class="col-md-2">
        <fieldset class="form-group mb-3 form-group-input" v-authorize="['lotesScLotesAuditar', 'lotesScLotesReabrir']">
          <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" style="color: white;">.</legend>
          <div>
            <b-button v-authorize="'lotesScLotesAuditar'" v-if="!disabled && !guiaEncerrada" pill variant="primary" @click="encerrarAuditoria">
              <i class="uil uil-save"></i>
              Encerrar auditoria
            </b-button>

            <!-- status == translateStatusAuditoria -->
            <b-button v-authorize="'lotesScLotesReabrir'" v-if="guiaEncerrada" pill variant="info" @click="reabrirAuditoria">
              <i class="uil uil-sync-exclamation"></i>
              Reabrir auditoria
            </b-button>
          </div>
        </fieldset>
      </div>
      
      <f-input
        v-if="!disabled && !guiaEncerrada && userAttrId == ATRIBUICAO.ADMINISTRATIVO"
        name="representandoUsuario" 
        v-model="model.representacao.label"
        label="Representando"
        :modelErrors="errors"
        :cols="5"
        disabled
      >
        <template #append>
          <b-button variant="light" @click="trocarRepresentante"><i class="uil uil-sync"></i></b-button>
        </template>
      </f-input>
    </div>

    <div class="row">
      <div class="col-md-12 filtro-inline">
        <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
        <b-form-group label="Filtros" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="filtroTabela"
            v-model="filtro.tabela"
            :options="filtroTabela"
            :aria-describedby="ariaDescribedby"
            name="filtroTabela"
          />
        </b-form-group>
      </div>

      <div class="col-md-3 filtro-inline">
        <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
        <b-form-group label="Glosas" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="filtroGlosas"
            v-model="filtro.glosas"
            :options="filtroGlosas"
            :aria-describedby="ariaDescribedby"
            name="filtroGlosas"
          />
        </b-form-group>
      </div>

      <div class="col-md-9 filtro-inline">
        <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
        <b-form-group label="Status" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="filtroStatus"
            v-model="filtro.status"
            :options="filtroStatus"
            :aria-describedby="ariaDescribedby"
            name="filtroStatus"
          />
        </b-form-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      :items="model.itensGuia"
      :fields="fields"
      :filter="filtro"
      :filter-function="filtroExec"
      primary-key="id"
    >
      <template #head(selection)>
        <b-form-checkbox v-if="filtro.selecao.length > 0"
          v-model="variosSelecionados"
          title="Desmarcar similares"
        />
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-if="existemSimilares(data.item.id)"
          v-model="filtro.selecao"
          :value="data.item.id"
          title="Selecionar similares"
        />
      </template>
      <template #cell(actions)="data">
        <b-button-group size="sm">
          <b-button v-if="
            disabled || guiaEncerrada ||
            (userRepAttrId == ATRIBUICAO.ADMINISTRATIVO && !(model.representacao && model.representacao.id > 0)) ||
            (data.item.ultimaAtribuicaoExecutouGlosa && data.item.ultimaAtribuicaoExecutouGlosa < userRepAttrId)
          "
            variant="outline-primary"
            :title="data.item.ultimaAtribuicaoExecutouGlosa == ATRIBUICAO.MEDICO ? 'editado por médico' : 'ver'"
            v-authorize="'lotesScLotesAuditar'"
            @click="$router.push({
              name: 'faturamento.lotes.formauditoria.visualizar',
              params: {
                arquivoId: $route.params.arquivoId,
                guiaIndex: $route.params.guiaIndex,
                numeroLote: $route.params.numeroLote,
                itemGuiaIds: JSON.stringify(filtro.selecao.length > 0 ? filtro.selecao : [data.item.id]),
              },
            })"
          >
            <i class="uil uil-eye icon-size"></i>
          </b-button>

          <b-button v-if="
            !disabled && !guiaEncerrada && userAttrId &&
            (userRepAttrId != ATRIBUICAO.ADMINISTRATIVO || (model.representacao && model.representacao.id > 0)) &&
            (!data.item.ultimaAtribuicaoExecutouGlosa || data.item.ultimaAtribuicaoExecutouGlosa >= userRepAttrId)
          "
            variant="outline-primary"
            :title="`Auditar${filtro.selecao.length > 0 ? ' selecionados' : ''}`"
            small
            v-authorize="'lotesScLotesAuditar'"
            @click="$router.push({
              name: 'faturamento.lotes.formauditoria.auditar',
              params: {
                arquivoId: $route.params.arquivoId,
                guiaIndex: $route.params.guiaIndex,
                numeroLote: $route.params.numeroLote,
                itemGuiaIds: JSON.stringify(filtro.selecao.length > 0 ? filtro.selecao : [data.item.id]),
              },
            })"
          >
            <i class="uil uil-file-edit-alt icon-size"></i>
          </b-button>
        </b-button-group>
      </template>
    </b-table>

    <template #footer>
      <button class="btn btn-info px-5" @click="voltar()" type="button">Voltar</button>
    </template>

    <!-- o v-model não está funcionando direito, o código abaixo vai controlar qnd ele deve ser aberto -->
    <!-- nem no próprio vue-bootstrap está funcionando: https://bootstrap-vue.org/docs/components/modal#prevent-closing -->
    <b-modal
      ref="modal-representacao"
      title="Representação"
      :hideHeaderClose="true"
      :centered="true"
      ok-only
      @hidden="ignorarRepresentante"
      @ok="utilizarRepresentante"
    >
      <div class="filtro-inline">
        <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
        <b-form-group label="Selecione o tipo de usuário:">
          <b-form-radio-group
            id="representacaoTipo"
            v-model="modalRepresentacao.representanteTipoId"
            :options="modalRepresentacao.optsAtribuicao"
            name="representacaoTipo"
          />
        </b-form-group>

        <f-selectsearch
          label="Selecione o usuário:"
          name="modalUsuarioId"
          ref="modalUsuarioId"
          v-model="modalRepresentacao.representanteId"
          :disabled="!modalRepresentacao.representanteTipoId"
          :cols="12"
          :searchFunction="pesquisarRepresentante"
          @select="addRepresentante"
          labelField="nome"
        />
      </div>
    </b-modal>
  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import appConfig from "@/app.config";
import { translateStatusAuditoria, translateStatusAuditoriaDataset } from '../../../utils/mixins.statuses'
import { getGuiaDetalhe, encerrarAuditoriaGuia, reabrirAuditoriaGuia, getAllRepresentante } from "../../../services/faturamento.service";
import { messageSuccess } from '@/utils/messages'
import { trataErro } from "../../../utils/tratar.retornos";
import { messageConfirm } from "../../../utils/messages";
import { ATRIBUICAO, getAll as getAllAtribuicao } from "@/services/atribuicao.service";
import FSelectsearch from '@/components/Form/SelectSearch';

export default {
  page: {
    title: "Lotes de faturamento",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CardForm,
    FSelectsearch
  },
  data() {
    return {
      ATRIBUICAO,
      userAttrId: null,
      userRepAttrId: null,
      loading: true,
      disabled: true,
      model: {
        solicitacaoInternacao: null,
        hospital:	null,
        rede:	null,
        tipoGuia:	null,
        periodoIni:	null,
        periodoFim:	null,
        convenio:	null,
        auditadoPor: null,
        itensGuia: [],
        representacao: {
          id: null,
          label: null,
        },
        status: null,
        statusComAlteracao: false,
      },
      fields: [
        {
          label: '',
          key: 'selection',
        },
        {
          label: 'Data',
          key: 'data',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.data(value);
          },

        },
        {
          label: 'Hora Inicial',
          key: 'horaIni',
        },
        {
          label: 'Hora Final',
          key: 'horaFim',
        },
        {
          label: 'Tabela',
          key: 'tabelaDescritivo',
          tdClass: (value, key, item) => {
            if (item.tabelaCodigo != item.tabelaCodigoOri && item.tabelaCodigoOri) {
              return 'dado-editado';
            }
            return '';
          },
          tdAttr: (value, key, item) => {
            let attr = {};
            if (item.tabelaCodigo != item.tabelaCodigoOri && item.tabelaCodigoOri) {
              attr.title = 'Original: ' + item.tabelaCodigoOri;
            }
            return attr;
          },
        },
        {
          label: 'Código',
          key: 'procedimentoCodigo',
          tdClass: (value, key, item) => {
            if (item.procedimentoCodigo != item.procedimentoCodigoOri && item.procedimentoCodigoOri) {
              return 'dado-editado';
            }
            return '';
          },
          tdAttr: (value, key, item) => {
            let attr = {};
            if (item.procedimentoCodigo != item.procedimentoCodigoOri && item.procedimentoCodigoOri) {
              attr.title = 'Original: ' + item.procedimentoCodigoOri;
            }
            return attr;
          },
        },
        {
          label: 'Descrição',
          key: 'procedimentoDescricao',
          tdClass: (value, key, item) => {
            if (item.procedimentoDescricao != item.procedimentoDescricaoOri && item.procedimentoDescricaoOri) {
              return 'dado-editado';
            }
            return '';
          },
          tdAttr: (value, key, item) => {
            let attr = {};
            if (item.procedimentoDescricao != item.procedimentoDescricaoOri && item.procedimentoDescricaoOri) {
              attr.title = 'Original: ' + item.procedimentoDescricaoOri;
            }
            return attr;
          },
        },
        {
          label: 'Vlr Unitário',
          headerAbbr: 'Valor Unitário',
          key: 'valorUnitario',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.toCurrency(value);
          },
        },
        {
          label: 'Qtd',
          headerAbbr: 'Quantidade',
          key: 'quantidade',
        },
        {
          label: 'Redução / Acréscimo',
          key: 'reducaoAcrescimo',
          formatter: (value/*, key, item*/) => {
            if (!value) return '';
            return value.toFixed(4).replace('.', ',');
          },
        },
        {
          label: 'Vlr Total',
          headerAbbr: 'Valor Total',
          key: 'valorTotal',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.toCurrency(value);
          },
        },
        {
          label: 'Qtd Auditada',
          headerAbbr: 'Quantidade Auditada',
          key: 'quantidadeAuditada',
        },
        
        {
          label: 'Vlr Glosa',
          headerAbbr: 'Valor Glosa',
          key: 'valorGlosa',
          formatter: (value, /*key,item*/ ) => {
              return this.$options.filters.toCurrency(value);
          },
          tdClass (value,/* key, item*/) {
            if (value < 0.0) return "valor-negativo"
            if (value > 0.0) return "valor-positivo"
            return ''
          }
        },
         
        {
          label: 'Vlr Líquido',
          headerAbbr: 'Valor Auditado',
          key: 'valorTotalAuditado',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.toCurrency(value);
          },
          tdClass: (value, key, item) => {
            if (item.valorTotal && item.valorTotalAuditado) {
              let valorTotalAuditado = this.$options.filters.toDecimal(item.valorTotalAuditado, 2);
              let valorTotal = this.$options.filters.toDecimal(item.valorTotal , 2);

              if (valorTotalAuditado < valorTotal) return "valor-positivo"
              if (valorTotalAuditado > valorTotal) return "valor-negativo"
              return '';
            }
            return '';
          }
        },

        {
          label: 'Status',
          key: 'status',
          formatter: (value, key, item) => {
            if (item.motivoPendenciaAuditoriaId > 0 && value != 'A') return 'Aguardando';
            return translateStatusAuditoria(value, item.statusComAlteracao);
          },
        },
        {
          label: 'Ações',
          key: 'actions',
          thClass: 'text-center', 
          tdClass: 'text-center'
        },
      ],
      filtro: {
        tabela: 'all',
        glosas: 'all',
        status: 'all',
        selecao: [],
      },
      filtroTabela: [{
        text: 'Todos',
        value: 'all',
      }],
      filtroGlosas: [{
        text: 'Todos',
        value: 'all',
      }, {
        text: 'Itens glosados',
        value: 'glosados',
      }],
      filtroStatus: [{
        text: 'Todos',
        value: 'all',
      }],
      modalRepresentacao: {
        optsAtribuicao: [],
        representanteTipoId: null,
        representanteId: null,
        representanteLabel: null,
      },
    };
  },
  computed: {
    variosSelecionados: {
      get() {
        return this.filtro.selecao.length > 0;
      },
      set(val) {
        if (!val) {
          this.filtro.selecao = [];
        }
      },
    },
    guiaEncerrada() {
      return this.model.status == 'A'
    },
    periodoFaturamento() {
      return this.$options.filters.dataHora(this.model.periodoIni) + ' a ' + this.$options.filters.dataHora(this.model.periodoFim);
    },
  },
  created() {
    this.$route.meta.breadcrumbs[2].text = this.$route.params.numeroLote
  },
  mounted() {
    this.disabled = !this.$route.meta.editavel;
    this.loading = true;

    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.userAttrId = user.atribuicaoId;
      this.userRepAttrId = user.atribuicaoId;
    }

    getGuiaDetalhe(this.$route.params.arquivoId, this.$route.params.guiaIndex)
      .then(({ data }) => {
        for (let key in data) {
          if (key == 'representacao') {
            if (data[key]) {
              this.model[key] = data[key];
            }
          } else {
            this.model[key] = data[key];
          }
        }

        let representacao = JSON.parse(sessionStorage.getItem('representacao'));
        if (
          representacao &&
          representacao.arquivoId == this.$route.params.arquivoId &&
          representacao.guiaIndex == this.$route.params.guiaIndex &&
          representacao.numeroLote == this.$route.params.numeroLote &&
          (representacao.representando && representacao.representando.id > 0)
        ) {
          this.model.representacao.id = representacao.representando.id;
          this.model.representacao.label = representacao.representando.label;
          this.userRepAttrId = representacao.representando.tipoId;
        } else {
          if(this.model.representacao && this.model.representacao.tipoId) {
            this.userRepAttrId = this.model.representacao.tipoId;
          }

          if (this.model.representacao && this.model.representacao.id > 0) {
            sessionStorage.setItem('representacao', JSON.stringify({
              arquivoId: this.$route.params.arquivoId,
              guiaIndex: this.$route.params.guiaIndex,
              numeroLote: this.$route.params.numeroLote,
              representando: {
                id: this.model.representacao.id,
                tipoId: this.model.representacao.tipoId,
                label: this.model.representacao.label,
              },
            }));
          }
        }

        this.atualizarFiltro();

        this.conferirRepresentacao();
      })
      .finally(() => {
        this.loading = false;
      });
    
      Object.getOwnPropertyNames(translateStatusAuditoriaDataset()).forEach((statusCode) => {
        this.filtroStatus.push({
          text: translateStatusAuditoria(statusCode),
          value: statusCode,
        });
      });
  },
  methods: {
    addRepresentante(representante) {
      if (representante) {
        let tipoRepresentado = this.modalRepresentacao.optsAtribuicao.filter(m => m.value == this.modalRepresentacao.representanteTipoId)[0].text;

        this.modalRepresentacao.representanteId = representante.id;
        this.modalRepresentacao.representanteLabel = `${tipoRepresentado}: ${representante.nome}`;
      } else {
        this.modalRepresentacao.representanteId = null;
      }
    },
    atualizarFiltro() {
      this.filtroTabela = [{
        text: 'Todos',
        value: 'all',
      }];

      // TODO: puxar filtro da tabela do backend
      let filtrosUsados = [];
      this.model.itensGuia.forEach((item) => {
        if (!filtrosUsados.includes(item.tabelaDescritivo)) {
          filtrosUsados.push(item.tabelaDescritivo);
          this.filtroTabela.push({
            text: item.tabelaDescritivo,
            value: item.tabelaDescritivo,
          })
        }
      });
    },
    conferirRepresentacao(force) {
      if (this.userAttrId == ATRIBUICAO.ADMINISTRATIVO) {
        if ((force !== true) && (this.model.representacao && this.model.representacao.id > 0) && this.$refs['modal-representacao'].hide) {
          this.$refs['modal-representacao'].hide()
        } else {
          if (!this.modalRepresentacao.optsAtribuicao || this.modalRepresentacao.optsAtribuicao.length == 0) {
            getAllAtribuicao().then(({ data }) => {
              data.resultado.forEach(atribuicao => {
                if (atribuicao.id != ATRIBUICAO.ADMINISTRATIVO) {
                  this.modalRepresentacao.optsAtribuicao.push({
                    text: atribuicao.nome,
                    value: atribuicao.id,
                  })
                }
              })
            })
          }

          this.$refs['modal-representacao'].show()
        }
      }
    },
    ignorarRepresentante() {
      if (this.userAttrId == ATRIBUICAO.ADMINISTRATIVO && (!(this.model.representacao && this.model.representacao.id > 0))) {
        this.$router.push({
          name: 'faturamento.lotes.detalhe',
          params: {
            id: this.$route.params.arquivoId
          }
        });
      }
    },
    encerrarAuditoria() {
      messageConfirm(this, "Ao encerrar a auditoria a guia irá para o status Auditado. Esta ação só é reversível por administradores",
      "Deseja encerrar a auditoria?")
      .then((result) => {
        if (result.isConfirmed) {
          encerrarAuditoriaGuia(
            this.$route.params.arquivoId,
            this.$route.params.guiaIndex,
            this.model.representacao.id
          ).then(() => {
            messageSuccess(this, 'Auditoria da guia encerrada')
            this.voltar();
          }).catch((err) => {
            trataErro(err, this);
          })
        }
      })
    },
    existemSimilares(idSelecionado) {
      let selecaoModelBase = this.model.itensGuia.find((item) => {
        return item.id == idSelecionado;
      });
      
      if (selecaoModelBase.tabelaCodigo == '22') return false; // Não agrupar procedimentos.

      let itensSimilares = this.model.itensGuia.filter(row => {
        if (
          selecaoModelBase.tabelaDescritivo != row.tabelaDescritivo ||
          selecaoModelBase.procedimentoCodigo != row.procedimentoCodigo ||
          selecaoModelBase.procedimentoDescricao != row.procedimentoDescricao ||
          selecaoModelBase.reducaoAcrescimo != row.reducaoAcrescimo ||
          selecaoModelBase.valorUnitario != row.valorUnitario
        ) {
          return false;
        }
        return true;
      });

      return itensSimilares.length > 1;
    },
    filtroExec(row, filter) {
      if (filter.selecao.length > 0) {
        var idSelecionado = filter.selecao[0];
        let selecaoModelBase = this.model.itensGuia.find((item) => {
          return item.id == idSelecionado;
        });
        if (selecaoModelBase) {
          if (
            selecaoModelBase.tabelaDescritivo != row.tabelaDescritivo ||
            selecaoModelBase.procedimentoCodigo != row.procedimentoCodigo ||
            selecaoModelBase.procedimentoDescricao != row.procedimentoDescricao ||
            selecaoModelBase.reducaoAcrescimo != row.reducaoAcrescimo ||
            selecaoModelBase.valorUnitario != row.valorUnitario
          ) {
            return false;
          }
        } else {
          filter.selecao = []; // Evitar erros de programação
        }
      }

      if (filter.tabela != 'all' && filter.tabela != row.tabelaDescritivo) {
        return false;
      }

      if (filter.glosas == 'glosados' && row.status == 'NI') { // translateStatusAuditoria
        return false;
      }

      if (filter.status != 'all') {
        if (filter.status == 'W') {
          if (row.status == 'A' || !(row.motivoPendenciaAuditoriaId > 0)) {
            return false;
          }
        } else if (filter.status != row.status) {
          return false;
        } else if (filter.status != 'A' && row.motivoPendenciaAuditoriaId > 0) {
          return false;
        }
      }

      return true;
    },
    pesquisarRepresentante(query) {
      return getAllRepresentante({
        filtro: query,
        atribuicaoIds: [ this.modalRepresentacao.representanteTipoId ],
        ativo: true
      })
    },
    reabrirAuditoria() {
      messageConfirm(this, "Deseja retornar a guia para o status \"Em auditoria\"?", "Deseja reabrir a auditoria?")
        .then((result) => {
          if (result.isConfirmed) {
            reabrirAuditoriaGuia(
              this.$route.params.arquivoId,
              this.$route.params.guiaIndex,
              this.model.representacao.id
            ).then(() => {
              messageSuccess(this, 'Auditoria da guia reaberta')
              this.voltar();
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
    trocarRepresentante() {
      this.modalRepresentacao.representanteTipoId = null;
      this.modalRepresentacao.representanteId = null;
      this.modalRepresentacao.representanteLabel = null;

      this.conferirRepresentacao(true);
    },
    utilizarRepresentante() {
      this.model.representacao.id = this.modalRepresentacao.representanteId;
      this.model.representacao.label = this.modalRepresentacao.representanteLabel;
      this.userRepAttrId = this.modalRepresentacao.representanteTipoId;

      sessionStorage.setItem('representacao', JSON.stringify({
        arquivoId: this.$route.params.arquivoId,
        guiaIndex: this.$route.params.guiaIndex,
        numeroLote: this.$route.params.numeroLote,
        representando: {
          id: this.model.representacao.id,
          tipoId: this.model.representacao.tipoId,
          label: this.model.representacao.label,
        },
      }));

      this.$refs['modal-representacao'].hide()
    },
    voltar() {
      this.$router.push({name: 'faturamento.lotes.' + (this.disabled ? 'detalhe' : 'auditar'), params: { id: this.$route.params.arquivoId }})
    },
  }
};
</script>
